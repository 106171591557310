@font-face {
  font-family: "Inter";
  src: local("Inter-Regular"), url("./Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Medium"), url("./Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Medium"), url("./Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Bold"), url("./Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Inter";
  src: local("Inter-ExtraBold"), url("./Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: block;
}

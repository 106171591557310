@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Inter";
  height: 100%;
  width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
#root {
  @apply h-full max-w-full w-full mx-auto;
}

#root {
  @apply flex flex-col;
}

button:not(:disabled):active {
  transform: translateY(2px);
}

input[type="password"] {
  border: none;
}

input:focus,
[type="text"]:focus,
[type="password"]:focus {
  box-shadow: none !important;
  border-color: none !important;
  outline: none !important;
}

input:focus,
[type="number"]:focus,
[type="textarea"]:focus {
  box-shadow: none !important;
  border-color: none !important;
  outline: none !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border: 2px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

::-webkit-scrollbar-track {
  background: linear-gradient(
    to right,
    #e8e8e8 0%,
    #fff 33.99%,
    #fff 67.98%,
    #e8e8e8 100%
  );
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.react-grid-item.react-grid-placeholder {
  background: transparent !important;
}
